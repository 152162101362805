<template>
  <v-toolbar dark color="primary">
    <!-- <img src="/itc-logo.png" style="width: 3em"> -->
    <v-btn flat @click="$router.push('/manager/default')">{{ toolbarTitle }}</v-btn>

    <v-spacer></v-spacer>

    <v-btn flat @click="$router.push('/manager/worktime-record')">
      日別データ
    </v-btn>

    <v-btn flat @click="$router.push('/manager/aggregate')">
      一覧データ
    </v-btn>

    <v-btn flat @click="$router.push('/manager/automatic-aggregate')">
      集計データ
    </v-btn>

    <v-btn flat @click="$router.push('/manager/worktime')">
      勤務時間
    </v-btn>

    <v-btn flat @click="$router.push('/manager/overtime-work')">
      時間外勤務申請
    </v-btn>

    <v-btn flat @click="$router.push('/manager/leave-application')">
      休暇・申請
    </v-btn>

    <v-btn flat @click="$router.push('/manager/holiday')">
      休日設定
    </v-btn>

    <v-btn flat @click="$router.push('/manager/staff')">
      スタッフ
    </v-btn>

    <!-- <v-btn flat @click="logout">
      logout
    </v-btn> -->
    <!-- <v-icon class="ml-2" @click="logout">code</v-icon> -->
    <img src="../../logout.png" @click="logout" style="margin-left: 1em; width: 1.5em">
  </v-toolbar>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    toolbarTitle: ''
  }),
  methods: {
    logout () {
      axios.post('/api/manager/logout')
        .then(() => {
          this.$router.push('/manager')
        })
        .catch(() => {
          alert('通信エラー')
        })
    }
  },
  mounted () {
    this.toolbarTitle = localStorage.getItem('toolbarTitle')
  }
}
</script>
