<template>
  <v-layout style="background-color: white" column fill-height>
    <MobileHeader :val=staffName />
    <v-flex xs12>
      <v-card tile class="ma-2 mt-5">
        <v-card-text>
          <v-layout row wrap class="align-center">
            <v-flex xs2 class="text-xs-right">
              <v-btn flat icon @click="toDate(-1)">
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs8 class="text-xs-center">
              {{ date }}
            </v-flex>
            <v-flex xs2>
              <v-btn flat icon @click="toDate(1)">
                <v-icon>chevron_right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-divider />
          <v-list class="mt-5">
            <template v-for="item in items">
              <v-list-tile :key="item.id">
                <v-list-tile-content>
                  {{ convAt(item.record_at) }}
                </v-list-tile-content>
                <v-list-tile-content>
                  <v-list-tile-title>{{ convName(item.category) }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider :key="`divider-${item.id}`" />
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn outline color="grey" class="ma-a"
            @click="$router.push('/worktime-record')"
          >キャンセル</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import dayjs from 'dayjs'
import MobileHeader from '@/components/MobileHeader'

export default {
  name: 'MobileWorktimeRecordHistory',
  components: {
    MobileHeader
  },
  data: () => ({
    items: [],
    date: dayjs().format('YYYY-MM-DD'),
    worktimeRecordNames: {
      attendance: '出勤',
      leave: '退勤',
      outOfOffice: '外出',
      returnToOffice: '外戻',
      rest: '休入',
      restart: '休戻',
      amRest: '午前休入',
      amRestart: '午前休戻',
      pmRest: '午後休入',
      pmRestart: '午後休戻'
    }
  }),
  computed: {
    ...mapState(['staffName'])
  },
  mounted () {
    this.getHistory()
  },
  methods: {
    toDate (toN) {
      this.items = []
      this.date = dayjs(this.date).add(toN, 'week').format('YYYY-MM-DD')
      this.getHistory()
    },
    getHistory () {
      axios.get(`/api/worktime-record-history?date=${this.date}`)
        .then((res) => {
          this.items = res.data.map((item) => {
            const newItem = item
            if (item.is_leaving_spanning_date === 1) {
              newItem.record_at = dayjs(item.record_at).add(1, 'day').format('YYYY-MM-DD HH:mm:ss')
            } else {
              newItem.record_at = dayjs(item.record_at).format('YYYY-MM-DD HH:mm:ss')
            }
            return newItem
          })
          this.items.sort((a, b) => {
            if (a.record_at > b.record_at) {
              return 1
            }
            if (a.record_at < b.record_at) {
              return -1
            }
            return 0
          })
        })
        .catch((error) => {
          console.log(error)
          console.log('Error, get history!')
        })
    },
    convAt (datetime) {
      return dayjs(datetime).format('YYYY-MM-DD HH:mm:ss')
    },
    convName (name) {
      return this.worktimeRecordNames[name]
    }
  }
}
</script>
