<template>
  <div>
    <MyToolbar />
    <v-breadcrumbs :items="items" divider=">" class="pb-0"></v-breadcrumbs>
    <v-layout class="mt-2">
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="beginDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="beginDate"
              label="日付範囲（開始）"
              prepend-icon="event"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="beginDate"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="closeBeginDateMenu"
          />
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate"
              label="日付範囲（終了）"
              prepend-icon="event"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="endDate"
            locale="ja-jp"
            :day-format="date => new Date(date).getDate()"
            @input="closeEndDateMenu"
            />
        </v-menu>
      </v-flex>
      <v-flex xs2 class="pa-2">
        <v-select
          v-model.number="projectId"
          :items="projectItems"
          item-value="id"
          item-text="name"
          label="作業"
        />
      </v-flex>
      <v-flex xs3 class="pa-2">
        <StaffCombo :value.sync="staff" :groupId="groupId" />
      </v-flex>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="ma-2" @click="$router.push('/manager/project')">
        作業
      </v-btn>
    </v-layout>
    <v-card class="mb-3 ma-2">
      <v-toolbar flat color="white">
        <v-toolbar-title style="font-weight: bold; border-left: solid 5px #456ab8; padding-left: 0.3em">
          作業別集計データ
        </v-toolbar-title>
      </v-toolbar>
      <div style="display: block">
        <v-layout row wrap class="timetable project-aggregate-header">
          <v-flex xs2 class="pa-2 project-aggregate-th" style="font-weight: bold; padding-top: 2em !important" text-xs-center>
            氏名
          </v-flex>
          <v-flex xs10>
            <v-layout>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                出勤<br>回数
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th">
                休日<br>出勤<br>回数
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                遅刻<br>回数
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                早退<br>回数
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                遅刻<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                早退<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                休憩<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 2em !important">
                時間外
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                深夜<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                法定<br>時間内
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                法定<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                労働<br>時間
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th" style="padding-top: 1.3em !important">
                深夜<br>時間外
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-th">
                労働<br>時間<br>合計
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-divider />
          </v-flex>
        </v-layout>
        <v-alert :value="worktimeRecordItems.length == 0" color="error" icon="warning" outline>
          データがありません
        </v-alert>
      </div>
      <div class="tbody" style="font-size: 15px">
        <v-layout
          row
          wrap
          v-for="(item, idx) in automatic"
          :key="item.date"
          :class="['timetable', item.class_name === 'whiteCursor' ? 'whiteCursor' : 'grayCursor']"
          @mouseover="mouseover(idx)"
          @mouseleave="mouseleave"
          :style="{'border-bottom': '1px solid #ccc', 'background-color': bg === idx ? '#eeeeee' : ''}"
        >
          <v-flex xs2 class="pa-2 project-aggregate-td">
            <span class="subheading">{{ item.name }}</span>
          </v-flex>
          <v-flex xs10>
            <v-layout>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.workTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.holidayWorkTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.is_tardiness }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.is_early_withdrawal }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.tardiness_time | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.early_withdrawal_time | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.rest_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.overtime_workday_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.overtime_workday_midnight_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.legal_overtime_working_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.non_statutory_working_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.holidayWorkMinutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.overtime_holiday_workday_midnight_minutes | changeNotationToHourTime }}
              </v-flex>
              <v-flex xs1 class="pa-2 project-aggregate-td">
                {{ item.work_minute | changeNotationToHourTime }}
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import MyToolbar from '@/components/MyToolbar'
import itemTools from '@/mixins/item_tools.js'
import StaffCombo from '@/components/StaffCombo'

export default {
  name: 'ProjectAggregate',
  mixins: [itemTools],
  components: {
    MyToolbar,
    StaffCombo
  },
  data: () => ({
    groupId: 0,
    group: '指定なし',
    items: [
      {
        text: '集計データ',
        disabled: false,
        href: '/#/manager/automatic-aggregate'
      },
      {
        text: '作業別集計データ',
        disabled: true
      }
    ],
    bg: '',
    companyItem: {},
    leaveApplicationDataItems2: [],
    wDayList: ['日', '月', '火', '水', '木', '金', '土'],
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    endDateMenu: false,
    beginDate: moment().startOf('month').format('YYYY-MM-DD'),
    beginDateMenu: false,
    staff: '',
    staffItems: [],
    projectItems: [],
    projectId: 0,
    worktimeRecordItems: []
  }),
  filters: {
    changeNotationToHourTime: function (minute) {
      if (minute === 'leave-application') {
        return ''
      } else if (minute === 0 || minute < 0) {
        return '0'
      } else {
        return Math.floor(minute / 60) + ':' + (Math.floor(minute) % 60)
      }
    }
  },
  computed: {
    automatic () {
      let pItems = this.processItems(this.worktimeRecordItems, this.staff, this.companyItem, '', this.projectItems)
      let pItems2 = []

      pItems.forEach((item) => {
        if (item.date >= this.beginDate) {
          pItems2.push(item)
        }
      })

      return this.automaticprocessItems(pItems2, this.leaveApplicationDataItems2, 'false', {}, this.projectId)
    }
  },
  mounted () {
    this.getCompany()
    this.getProject()
    this.fetch()
  },
  methods: {
    closeBeginDateMenu () {
      this.beginDateMenu = false
      this.fetch()
    },
    closeEndDateMenu () {
      this.endDateMenu = false
      this.fetch()
    },
    mouseleave () {
      this.bg = ''
    },
    mouseover (idx) {
      this.bg = idx
    },
    getProject () {
      axios.get('/api/manager/project')
        .then((res) => {
          this.projectItems = []
          this.projectItems = res.data
          this.projectItems.unshift({ 'id': 0, 'name': '指定なし' })
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    getCompany () {
      axios.get('/api/manager/company')
        .then((res) => {
          this.companyItem = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データエラー[getCompany]')
        })
    },
    fetch () {
      axios.get('/api/manager/leave-application?begin_date=' + this.beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          this.leaveApplicationDataItems2 = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', '取得エラー[getLeaveApplication]')
        })

      // 週40時間を超えた場合の時間外の計算のために、日付指定（開始）より6日前から取得するようにする
      // 表示は日付指定（開始）より表示されるように制御する
      let beginDate = moment(this.beginDate).subtract(6, 'd').format('YYYY-MM-DD')

      axios.get('/api/manager/worktime-record?begin_date=' + beginDate + '&end_date=' + this.endDate)
        .then((res) => {
          this.worktimeRecordItems = res.data
        })
        .catch((error) => {
          this.errorDecision(error, '/manager', 'データ取得エラー[getWorktimeRecord]')
        })
    }
  }
}
</script>

<style>
.project-aggregate-header {
  background-color: #e9eef7;
  border-top: 1px solid rgba(0,0,0,0.12);
}
.project-aggregate-th {
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.12);
}
.project-aggregate-td {
  border-right: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>

<style scoped>
.tbody {
  display: block;
  overflow-y: scroll;
  height: 60vh;
}
.tbody::-webkit-scrollbar {
    display:none;
}
.grayCursor {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
.whiteCursor {
  border-left: 1px solid rgba(0,0,0,0.12);
  text-align: center;
}
</style>
